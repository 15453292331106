<template>
  <div>
    <h1>Editar TDA</h1>

    <div class="cities-wrapper">
      <v-select
        :items="partners"
        item-text="name"
        item-value="id"
        label="Parceira"
        v-model="partner"
        :loading="loadingPartners"
      ></v-select>
      <table v-if="partner">
        <tr v-for="city in parsedCities" :key="city.city_id">
          <td>{{ city.city_name }}</td>
          <td>
            <p v-if="loadingCities">Carregando...</p>
            <input
              v-if="!loadingCities"
              v-model="city.fee"
              type="text"
              placeholder="TDA"
            />
          </td>
        </tr>
      </table>

      <v-btn
        :loading="savingFees"
        class="mt-2"
        @click="saveFees"
        color="success"
        :disabled="!partner"
        >Salvar</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    partner() {
      this.getCityFees();
    },
  },
  computed: {
    parsedCities() {
      let data = [];

      this.cities.map((city) => {
        let fee = this.fees.find((fee) => fee.city_id === city.id);

        let newFee = {
          city_id: city.id,
          city_name: city.name,
          partner_id: this.partner,
          fee: fee ? fee.value : 0,
        };

        data.push(newFee);
      });

      return data;
    },
  },
  mounted() {
    this.getCities();
    this.getPartners();
  },
  data() {
    return {
      cities: [],
      partners: [],
      fees: [],
      partner: null,
      loadingCities: false,
      loadingPartners: false,
      savingFees: false,
    };
  },
  methods: {
    async saveFees() {
      let data = [];

      this.parsedCities.map((city) => {
        data.push(city);
      });

      this.savingFees = true;

      try {
        await this.$http.post("api/update-fees/city-fees", { fees: data });
        this.$toast.success("Salvo 😄");
      } catch (error) {
        this.$toast.error("Erro ao salvar dados");
      }

      this.savingFees = false;
    },
    async getCityFees() {
      this.loadingCities = true;

      try {
        const { data } = await this.$http.get(
          `api/city-fees?partner_id=${this.partner}`
        );
        this.fees = data;
      } catch (error) {
        this.$toast.error("Erro ao carregar taxas");
      }

      this.loadingCities = false;
    },
    async getPartners() {
      this.loadingPartners = true;

      try {
        const { data } = await this.$http.get("api/partners");
        this.partners = data;
      } catch (error) {
        this.$toast.error("Erro ao carregar parceiras");
      }

      this.loadingPartners = false;
    },
    async getCities() {
      try {
        const { data } = await this.$http.get("api/cities");
        this.cities = data;
      } catch (error) {
        this.$toast.error("Erro ao carregar cidades");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: solid 2px #44444455;

    td {
      padding: 5px;
    }

    &:hover {
      background: #ddd;
    }
  }
}

input {
  border: solid 1px #44444477;
  border-radius: 10px;
  padding: 5px;

  &:focus {
    outline: solid #44f;
  }
}
</style>
